<template>
  <div
    class="vmx-header-container"
    :class="tileNavigation ? 'tile-navigation' : ''"
  >
    <div
      v-if="terminal"
      id="terminal_container-header"
    >
      <img
        class="terminal_container-header-logo"
        :src="terminalHeaderImage"
      />
    </div>
    <header-container
      class="vmx-header"
      :background="background"
      :breakpoint="breakpoint"
      id="vmx-header"
    >
      <app-navigation
        :app-navigation-items="appNavigationItems"
        v-if="appNavigationActive && isApp && (width <= breakpoint)"
      />
      <banner-menu-layout v-if="!fullWidthBanner || width <= breakpoint && showHeader" class="vmx-header__bannerMenu-layout" ref="mobileHeaderHeightRef">
        <content-container class="vmx-header__bannerMenu">
          <banner-logos
            class="vmx-header__bannerMenu-left"
            :banners="banners"
            @sideNavigationToggle="toggleNav"
            :header-menu="menu"
          />
          <banner-menu-right-container
            class="vmx-header__bannerMenu-right-container"
          >
            <banner-menu
              class="vmx-header__bannerMenu-right"
              :items="bannerMenuItems"
              :my-congress="menu.myCongress"
              :max-congress-items="menu.maxCongressItems"
            />
            <social-media-icons
              v-if="socialMediaIconsActive"
              class="vmx-header__bannerMenu-right--socialMediaIcons"
            />
          </banner-menu-right-container>
        </content-container>
      </banner-menu-layout>
      <div class="vmx-header__nav-container" ref="desktopHeaderHeightRef" v-if="showHeader">
        <navigation
          :class="[sideNavigationOpen ? 'mobile-navigation-open' : 'mobile-navigation-closed', 'vmx-header__nav']"
          :items="menuItems"
          ref="navigation"
          :width="width"
          :side-navigation-open="sideNavigationOpen"
          @sideNavigationToggle="toggleNav"
          :key="componentKey"
          :banners="banners"
        >
          <banner-menu
            :items="bannerMenuItems"
            :mobile-view="true"
            :my-congress="menu.myCongress"
            :max-congress-items="menu.maxCongressItems"
            :width="width"
            :my-congress-items="myCongressItems"
            :breakpoint="breakpoint"
            :custom-menu-items="customMenuItems"
          />
        </navigation>
      </div>
      <template v-if="sideNavigationOpen && showHeader">
        <background-overlay :style="overlayStyle" class="bannerMenu_background-overlay" />
      </template>
      <template v-if="fullWidthBanner && showHeader">
        <template v-if="isApp">
          <div class="bannerMenu_fullwidth-banner_container" :class="isVisible && hideHeaderBannerOnScroll ? 'visible' : 'hidden'" :style="fullWidthBannerBackground ? backgroundStyle : null">
            <div :class="fullWidthBannerBackground ? 'vm-container bannerMenu_fullwidth-banner' : 'bannerMenu_fullwidth-banner'">
              <component :is="tag(this.fullWidthBannerBackgroundUrl)" v-bind="tagProps(this.fullWidthBannerBackgroundUrl)">
                <img class="bannerMenu_fullwidth-banner_img" style="width: 100%" :src="fullWidthBannerLogo" />
              </component>
            </div>
          </div>
        </template>
        <template v-else-if="showHeader">
          <div class="bannerMenu_fullwidth-banner_container" :style="fullWidthBannerBackground ? backgroundStyle : null">
            <div :class="fullWidthBannerBackground ? 'vm-container bannerMenu_fullwidth-banner' : 'bannerMenu_fullwidth-banner'">
              <component :is="tag(this.fullWidthBannerBackgroundUrl)" v-bind="tagProps(this.fullWidthBannerBackgroundUrl)">
                <img class="bannerMenu_fullwidth-banner_img" style="width: 100%" :src="fullWidthBannerLogo" />
              </component>
            </div>
          </div>
        </template>
      </template>
    </header-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '~/utilities/utils'
import headerMixins from '~/mixins/headerMixins'
import externalTrackingMixin from '~/mixins/externalTrackingMixins'
import Navigation from './Navigation.vue'
import HeaderContainer from './HeaderContainer.vue'
import ContentContainer from './ContentContainer.vue'
import BannerLogos from './BannerLogos.vue'
import BannerMenu from './BannerMenu.vue'
import AppNavigation from './AppNavigation.vue'
import BannerMenuRightContainer from './BannerMenuRightContainer.vue'
import SocialMediaIcons from './SocialMediaIcons.vue'
import BackgroundOverlay from './BackgroundOverlay.vue'
import BannerMenuLayout from './BannerMenuLayout.vue'

export default {
  name: 'VMXHeader',
  components: {
    HeaderContainer,
    ContentContainer,
    BannerLogos,
    BannerMenu,
    Navigation,
    BackgroundOverlay,
    BannerMenuLayout,
    BannerMenuRightContainer,
    SocialMediaIcons,
    AppNavigation,
  },
  mixins: [headerMixins, externalTrackingMixin,],
  data() {
    return {
      width: window.innerWidth,
      sideNavigationOpen: false,
      componentKey: 0,
      isVisible: true,
      desktopHeaderHeight: 0,
      mobileHeaderHeight: 0,
    }
  },
  computed: {
    ...mapGetters({
      headerModel: 'header/model',
      configForKey: 'config/configForKey',
      user: 'user/user',
      event: 'instancesState/getEvent',
      language: 'instancesState/getLanguage',
      instance: 'instancesState/getInstance',
      menuDisabled: 'instancesState/getMenuDisabled',
      isAppContainer: 'instancesState/getIsAppContainer',
    }),
    isApp() {
      return this.isAppContainer
    },
    banners() {
      return this.headerConfig.banners
    },
    appNavigationItems() {
      return this.headerConfig.appNavigationItems
    },
    appNavigationActive() {
      if (this.headerConfig.appNavigationActive) {
        return this.headerConfig.appNavigationActive
      }
      return false
    },
    menu() {
      return this.headerConfig.menu
    },
    socialMediaIconsActive() {
      const items = this.headerConfig.menu?.socialMediaIconsActive
      if (items) {
        return items
      }
      return false
    },
    terminal() {
      return localStorage.getItem('isTerminal')
    },
    menuItems() {
      const items = this.headerConfig.menu?.menuItems
      if (items) {
        return items
      }
      return []
    },
    bannerMenuItems() {
      const items = this.headerConfig.menu?.bannerMenuItems
      if (items) {
        return items
      }
      return []
    },
    background() {
      return this.headerConfig.background
    },
    terminalHeaderImage() {
      return this.configForKey('app_config').config[0].defaults.terminal.terminalHeaderImage
    },
    myCongressItems() {
      const items = this.headerConfig.menu?.myCongressItems
      if (items) {
        return items
      }
      return []
    },
    customMenuItems() {
      const items = this.headerConfig.menu?.customMenuItems
      if (items) {
        return items
      }
      return []
    },
    backgroundOverlayColor() {
      if (this.headerConfig.menu?.backgroundOverlayColor) {
        return this.headerConfig.menu?.backgroundOverlayColor
      }
      return 'var(--primary)'
    },
    hideHeaderBannerOnScroll() {
      if (this.headerConfig.hideHeaderBannerOnScroll) {
        return this.headerConfig.hideHeaderBannerOnScroll
      }
      return false
    },
    overlayStyle() {
      return `background-color: ${this.backgroundOverlayColor};`
    },
    backgroundStyle() {
      const styles = `background-color: ${this.fullWidthBannerBackgroundColor};`
      return styles
    },
    showHeader() {
      if (this.isApp && this.appNavigationActive && this.width <= this.breakpoint) {
        return false
      }
      return true
    }
  },
  methods: {
    checkHeaderHeight() {
      if (this.isApp) {
        const mobileNav = this.$refs.mobileHeaderHeightRef
        this.mobileHeaderHeight = mobileNav ? mobileNav.clientHeight : 0
        localStorage.setItem('headerHeight', this.mobileHeaderHeight)
      }
      if (this.width <= this.breakpoint && !this.isApp) {
        const mobileNav = this.$refs.mobileHeaderHeightRef
        this.mobileHeaderHeight = mobileNav ? mobileNav.clientHeight : 0
        localStorage.setItem('headerHeight', this.mobileHeaderHeight)
      }
      if (this.width > this.breakpoint) {
        const desktopNav = this.$refs.desktopHeaderHeightRef
        this.desktopHeaderHeight = desktopNav ? desktopNav.clientHeight : 0
        localStorage.setItem('headerHeight', this.desktopHeaderHeight)
      }
    },
    onScroll(e) {
      if (e.target.scrollTop > 120) {
        this.isVisible = false
      }
      if (e.target.scrollTop === 0) {
        this.isVisible = true
      }
    },
    toggleNav() {
      this.sideNavigationOpen = !this.sideNavigationOpen
    },
    onResize() {
      this.width = window.innerWidth
      if (window.innerWidth > this.breakpoint) {
        this.sideNavigationOpen = false
      }
    },
    forceRerender() {
      this.componentKey += 1
    },
    /* tag() {
      if (this.fullWidthBannerBackgroundUrl) {
        return 'a'
      }
      return 'router-link'
    }, */
    tag() {
      if (!utils.isObject(this.fullWidthBannerBackgroundUrl) && utils.isUrl(this.fullWidthBannerBackgroundUrl)) {
        return 'a'
      }
      return 'router-link'
    },
    tagProps() {
      if (!this.fullWidthBannerBackgroundUrl) {
        return {}
      }
      const props = {}
      if (utils.isUrl(this.fullWidthBannerBackgroundUrl)) {
        props.href = this.formatHref(this.fullWidthBannerBackgroundUrl)
      } else {
        let route = null
        if (utils.isObject(this.fullWidthBannerBackgroundUrl)) {
          route = utils.appendDefaultParamsToRoute(this.fullWidthBannerBackgroundUrl)
        } else {
          route = utils.getRouteWithDefaultParams(this.fullWidthBannerBackgroundUrl)
        }
        props.to = route
      }
      return props
    },
  },
  mounted() {
    this.$root.$on('navRerender', () => {
      this.forceRerender()
    })
    if (window.location.href.indexOf('noheader') !== -1) {
      setTimeout(() => {
        localStorage.removeItem('isApp')
        document.getElementById('vmx-header').style.display = 'none'
        document.getElementById('page-footer').style.display = 'none'
      }, 100)
    }
    const container = document.getElementById('app')
    container.addEventListener('scroll', this.onScroll)
    this.checkHeaderHeight()
  },
  created() {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('resize', this.checkHeaderHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
    window.addEventListener('resize', this.checkHeaderHeight)
  },
  beforeDestroy() {
    const container = document.getElementById('app')
    container.removeEventListener('scroll', this.onScroll)
  },
  updated() {
    this.checkHeaderHeight()
  },
}
</script>

<style lang="scss">
@media (min-width: 1024px) {
  .vmx-header__nav-container {
    background-color: var(--header-navbar-bg);
    margin: auto;
  }
}
.bannerMenu__menu-item-content-tileNavigation {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  text-align: center;
}
._nav-item_tile {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0 !important;
}
.bannerMenu__menu-item-dropdown-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.5rem
}
.bannerMenu_dropdown-content-tile {
  position: absolute;
  margin-top: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0.5rem;
  border: 0.25px solid #fff;
}
.bannerMenu_fullwidth-logo_img-container {
  position: absolute;
    left: 0;
    top: 0;
}
.vm-container.bannerMenu_fullwidth-banner {
  padding: 0;
}
img.bannerMenu_fullwidth-logo_img {
    width: 75px;
    box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.16);
}
.vmx-header__logout-button._nav-item,
.vmx-header__login-button._nav-item {
    color: var(--header-navbar-bg-contrast);
    padding: 0 25px;
    white-space: nowrap;
}
.bannerMenu_fullwidth-banner_container._nav-item_tile {
  transition: all ease-in 2s;
}

.bannerMenu_fullwidth-banner_container.hidden {
  transform: translateY(-1000px);
  z-index: -1;
  height: 0px;
}
.bannerMenu_fullwidth-banner_container img {
  height: 100%;
  transition: all ease-in 2s;
}
.bannerMenu_fullwidth-banner_container.hidden img {
  height: 0px;
}
</style>
