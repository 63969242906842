import store from '~/store'
import i18n from '../i18n'

export default function ({ next, to }) {
  const route = to
  let resourceSet = 'industrySet'
  const identityPages = [
    'LoginPage',
    'CreateAccountPage',
    'PasswordRetrievalPage',
    'ConfirmationPage',
    'ResetPasswordPage',
  ]
  const basicPages = [
    'AuthPage',
    'HoldingPage',
    'NoaccessPage',
  ]
  const userPages = [
    'UserFormPage',
  ]
  if (identityPages.includes(route.name)) {
    resourceSet = 'identitySet'
  }
  if (basicPages.includes(route.name)) {
    resourceSet = 'baseSet'
  }
  if (userPages.includes(route.name)) {
    resourceSet = 'userSet'
  }
  i18n.loadLocaleAsync(store.getters['instancesState/getLanguage'], resourceSet).then(() => {
    store.commit('instancesState/setInitializedHead', true)
    next()
  }).catch((err) => {
    store.commit('instancesState/setInitializedHead', true)
    console.error(err)
  })
}
