import store from '~/store'
import userState from '../states/user'

export default async function ({ next, router, to }) {
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  const preview = store.getters['instancesState/getPreview']

  const user = await userState.getUser(instance)

  const [backendConfig] = store.getters['config/configForKey']('app_config').config
  if (backendConfig.defaults?.prefillUserform && user) {
    await store.dispatch('user/userFormComplete')
  }
  const userFormFilled = store.getters['user/userFormFilled']
  const superUser = store.getters['instancesState/getSuperUserToggle']

  // eslint-disable-next-line max-len
  if (backendConfig.defaults?.prefillUserform && !userFormFilled && !superUser && !preview && user != null && to.name !== 'AssemblyPage') {
    router.push({
      name: 'UserFormPage',
      params: {
        instance,
        event,
        language,
      },
    })
  } else {
    next()
  }
}
