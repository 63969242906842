const manageInstances = {
  staging: {
    oauth2: {
      authorizeUrl: 'https://stagingoauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://apistaging.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://stagingid.m-anage.com',
    webformsUrl: 'https://staging.m-anage.com',
    mtv: {
      baseApi: 'staging.api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-dev.m-anage.com',
    signalrHub: 'https://connect-dev.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
    sso: {
      url: '/Account/LoginVMESMO',
    },
  },
  local: {
    oauth2: {
      authorizeUrl: 'http://localhost:54304/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'http://localhost:58470',
    vmx: {
      programBaseUrl: 'http://localhost:8100',
    },
    identityUrl: 'https://localhost:44381',
    webformsUrl: 'http://localhost:60413',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-dev.m-anage.com',
    signalrHub: 'https://connect-dev.m-anage.com',
    dataInsight: 'https://data-insight-dev.m-anage.com',
  },
  qa: {
    oauth2: {
      authorizeUrl: 'https://qaoauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://qaapi.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://qaid.m-anage.com',
    webformsUrl: 'https://qa.m-anage.com',
    mtv: {
      baseApi: 'eadv-api-lb.virtual-meeting.net',
    },
    sso: {
      url: '/Account/LoginSalesforce',
    },
    surveyApi: 'https://survey-dev.m-anage.com',
    signalrHub: 'https://connect-dev.m-anage.com',
    dataInsight: 'https://data-insight-dev.m-anage.com',
  },
  qa2: {
    oauth2: {
      authorizeUrl: 'https://qa2-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://qa2-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://qa2-id.m-anage.com',
    webformsUrl: 'https://qa2.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-dev.m-anage.com',
    signalrHub: 'https://connect-dev.m-anage.com',
    dataInsight: 'https://data-insight-dev.m-anage.com',
    sso: {
      url: '/Account/LoginSalesforce',
    },
  },
  qa3: {
    oauth2: {
      authorizeUrl: 'https://qa3-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://qa3-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://qa3-id.m-anage.com',
    webformsUrl: 'https://qa3.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-dev.m-anage.com',
    signalrHub: 'https://connect-dev.m-anage.com',
    dataInsight: 'https://data-insight-dev.m-anage.com',
  },
  qa4: {
    oauth2: {
      authorizeUrl: 'https://qa4-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://qa4-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://qa4-id.m-anage.com',
    webformsUrl: 'https://qa4.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-dev.m-anage.com',
    signalrHub: 'https://connect-dev.m-anage.com',
    dataInsight: 'https://data-insight-dev.m-anage.com',
  },
  dev: {
    oauth2: {
      authorizeUrl: 'https://devoauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://devapi.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://devid.m-anage.com',
    webformsUrl: 'https://dev.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-dev.m-anage.com',
    signalrHub: 'https://connect-dev.m-anage.com',
    dataInsight: 'https://data-insight-dev.m-anage.com',
    sso: {
      url: '/Account/LoginVMESMO',
    },
  },
  release: {
    oauth2: {
      authorizeUrl: 'https://oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://id.m-anage.com',
    webformsUrl: 'https://www.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  easd: {
    oauth2: {
      authorizeUrl: 'https://easdoauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    vmx: {
      programBaseUrl: '../../../programme',
    },
    apiBaseUrl: 'https://easdapi.m-anage.com',
    identityUrl: 'https://easdid.m-anage.com',
    webformsUrl: 'https://easd.m-anage.com',
    mtv: {
      baseApi: 'easd-api-lb.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  euretina: {
    oauth2: {
      authorizeUrl: 'https://hub-oauth.euretina.org/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    vmx: {
      programBaseUrl: '../../../programme',
    },
    apiBaseUrl: 'https://hub-api.euretina.org',
    identityUrl: 'https://hub-id.euretina.org',
    webformsUrl: 'https://hub.euretina.org',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
    sso: {
      url: '/AccountExternal/CventLogin',
      provider: 'euretina',
    },
  },
  escmid: {
    oauth2: {
      authorizeUrl: 'https://escmidoauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    vmx: {
      programBaseUrl: '../../../programme',
    },
    apiBaseUrl: 'https://escmidapi.m-anage.com',
    identityUrl: '',
    webformsUrl: 'https://escmid.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  congrex: {
    oauth2: {
      authorizeUrl: 'https://oauth.congrex.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://api.congrex.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://id.congrex.com',
    webformsUrl: 'https://booking.congrex.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  kukm: {
    oauth2: {
      authorizeUrl: 'https://oauth.kukm-conferences.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://api.kukm-conferences.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://id.kukm-conferences.com',
    webformsUrl: 'https://www.kukm-conferences.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  'dev-eular': {
    oauth2: {
      authorizeUrl: 'https://dev-eular-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://dev-eular-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://dev-eular-id.m-anage.com',
    webformsUrl: 'https://dev-eular.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  'dev-eraedta': {
    oauth2: {
      authorizeUrl: 'https://dev-eraedta-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://dev-eraedta-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://dev-eraedta-id.m-anage.com',
    webformsUrl: 'https://dev-eraedta.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  eular: {
    oauth2: {
      authorizeUrl: 'https://oauth-congress.eular.org/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://api-congress.eular.org',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://id-congress.eular.org',
    webformsUrl: 'https://account-congress.eular.org',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  efort: {
    oauth2: {
      authorizeUrl: 'https://otcommunityoauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://otcommunityapi.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://otcommunityid.m-anage.com',
    webformsUrl: 'https://otcommunity.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  ese: {
    oauth2: {
      authorizeUrl: 'https://ese-hormonesoauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://ese-hormonesapi.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://ese-hormonesid.m-anage.com',
    webformsUrl: 'https://ese-hormones.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  oic: {
    oauth2: {
      authorizeUrl: 'https://oic-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://oic-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://oic-id.m-anage.com',
    webformsUrl: 'https://oic.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  mevents: {
    oauth2: {
      authorizeUrl: 'https://mevents-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://mevents-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: '',
    webformsUrl: 'https://mevents.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  eraedta: {
    oauth2: {
      authorizeUrl: 'https://era-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://era-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://era-id.m-anage.com',
    webformsUrl: 'https://era.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight-dev.m-anage.com',
    certificate: 'https://era-edtaapps.m-anage.com/Certificates/Evaluation/',
  },
  era: {
    oauth2: {
      authorizeUrl: 'https://era-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://era-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://era-id.m-anage.com',
    webformsUrl: 'https://era.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight-dev.m-anage.com',
    certificate: 'https://era-edtaapps.m-anage.com/Certificates/Evaluation/',
  },
  eadv: {
    oauth2: {
      authorizeUrl: 'https://eadvoauth.m-anage.com/OAuth2/Authorize',
      clientId: '9CF6E258-AB25-4C47-86CA-E53CE3012870',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://eadvapi.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://eadvid.m-anage.com',
    webformsUrl: 'https://eadv.m-anage.com',
    mtv: {
      baseApi: 'eadv-api-lb.virtual-meeting.net',
    },
    sixConnix: 'https://eadv.m-anage.com/eadvvirtual2020/en-GB/External/SixConnex',
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  'dev-eadv': {
    oauth2: {
      authorizeUrl: 'https://dev-eadv-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://dev-eadv-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://dev-eadv-id.m-anage.com',
    webformsUrl: 'https://dev-eadv.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  'dev-easd': {
    oauth2: {
      authorizeUrl: 'https://dev-easd-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://dev-easd-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://dev-easd-id.m-anage.com',
    webformsUrl: 'https://dev-easd.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  eanm: {
    oauth2: {
      authorizeUrl: 'https://services-oauth.eanm.org/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://services-api.eanm.org',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://services-id.eanm.org',
    webformsUrl: 'https://services.eanm.org',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  esociety: {
    oauth2: {
      authorizeUrl: 'https://esociety-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://esociety-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: '',
    webformsUrl: 'https://esociety.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  ean: {
    oauth2: {
      authorizeUrl: 'https://ean-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://ean-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://ean-id.m-anage.com',
    webformsUrl: 'https://ean.m-anage.com',
    sso: {
      url: '/Account/LoginSalesforce',
    },
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  esot: {
    oauth2: {
      authorizeUrl: 'https://esot-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://esot-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://esot-id.m-anage.com',
    webformsUrl: 'https://esot.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  ers: {
    oauth2: {
      authorizeUrl: 'https://ers-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://ers-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://ers-id.m-anage.com',
    webformsUrl: 'https://ers.m-anage.com',
    sso: {
      url: '/Account/LoginERS',
    },
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  'dev-esmo': {
    oauth2: {
      authorizeUrl: 'https://dev-esmo-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://dev-esmo-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://dev-esmo-id.m-anage.com/',
    webformsUrl: 'https://dev-esmo.m-anage.com/',
    sso: {
      url: '/Account/LoginVMESMO',
    },
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  esmo: {
    oauth2: {
      authorizeUrl: 'https://meetingservicesoauth.esmo.org/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://meetingservicesapi.esmo.org',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://meetingservicesid.esmo.org/',
    webformsUrl: 'https://meetingservices.esmo.org/',
    sso: {
      url: '/Account/LoginVMESMO',
    },
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  otw: {
    oauth2: {
      authorizeUrl: 'https://otw-connectoauth.leipziger-messe.de/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://otw-connectapi.leipziger-messe.de',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://otw-connectid.leipziger-messe.de',
    webformsUrl: 'https://otw-connect.leipziger-messe.de',
    sso: {
      url: '/Account/LoginLeipziger',
      context: 'Event',
    },
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  escrs: {
    oauth2: {
      authorizeUrl: 'https://escrs-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://escrs-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://escrs-id.m-anage.com',
    webformsUrl: 'https://escrs.m-anage.com',
    sso: {
      url: '/Account/LoginESCRSv1',
    },
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  eha: {
    oauth2: {
      authorizeUrl: 'https://congress-oauth.ehaweb.org/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://congress-api.ehaweb.org',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://congress-id.ehaweb.org',
    webformsUrl: 'https://congress.ehaweb.org',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  easl: {
    oauth2: {
      authorizeUrl: 'https://events-oauth.easl.eu/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://events-api.easl.eu',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://events-id.easl.eu',
    webformsUrl: 'https://events.easl.eu',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  kit: {
    oauth2: {
      authorizeUrl: 'https://fams-oauth.kit-group.org/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://fams-api.kit-group.org',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://fams-id.kit-group.org',
    webformsUrl: 'https://fams.kit-group.org',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  dgn: {
    oauth2: {
      authorizeUrl: 'https://dgn-oauth.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://dgn-api.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://dgn-id.m-anage.com',
    webformsUrl: 'https://dgn.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
  us: {
    oauth2: {
      authorizeUrl: 'https://oauth.us.m-anage.com/OAuth2/Authorize',
      clientId: '60A62F84-28D1-458D-80ED-D350893C1940',
      scope: 'Api/User',
    },
    apiBaseUrl: 'https://api.us.m-anage.com',
    vmx: {
      programBaseUrl: '../../../programme',
    },
    identityUrl: 'https://id.us.m-anage.com',
    webformsUrl: 'https://us.m-anage.com',
    mtv: {
      baseApi: 'api.virtual-meeting.net',
    },
    surveyApi: 'https://survey-prod.m-anage.com',
    signalrHub: 'https://connect.m-anage.com',
    dataInsight: 'https://data-insight.m-anage.com',
  },
}
const domainConfig = {
  'staging.lan': {
    instance: 'staging',
    defaultEvent: 'qaservicechecks',
    defaultLanguage: 'en-GB',
  },
  'dev.lan': {
    instance: 'dev',
    defaultEvent: 'qaservicechecks',
    defaultLanguage: 'en-GB',
  },
  'eha2021.ehaweb.org': {
    instance: 'eha',
    defaultEvent: 'eha2022',
    defaultLanguage: 'en-US',
  },
  'vmxeular-dev.m-anage.com': {
    instance: 'dev-eular',
    defaultEvent: 'eulr22',
    defaultLanguage: 'en-GB',
  },
  'eha22.ehaweb.org': {
    instance: 'eha',
    defaultEvent: 'eha2022',
    defaultLanguage: 'en-US',
  },
  'eha2023.ehaweb.org': {
    instance: 'eha',
    defaultEvent: 'eha2023',
    defaultLanguage: 'en-GB',
  },
  'www.derma-tagungen.de': {
    instance: 'release',
    defaultEvent: 'kopra2024',
    defaultLanguage: 'de-DE',
  },
  'virtualcongress.eular.org': {
    instance: 'eular',
    defaultEvent: 'eulr22',
    defaultLanguage: 'en-GB',
  },
  'esmocongress.esmo.org': {
    instance: 'esmo',
    defaultEvent: 'esmo2023',
    defaultLanguage: 'en-GB',
  },
  'asiacongress.esmo.org': {
    instance: 'esmo',
    defaultEvent: 'asia2023',
    defaultLanguage: 'en-GB',
  },
  'esmocongress-dev.esmo.org': {
    instance: 'dev-esmo',
    defaultEvent: 'esmo2023',
    defaultLanguage: 'en-GB',
  },
  'asiacongress-dev.esmo.org': {
    instance: 'esmo',
    defaultEvent: 'asia2023',
    defaultLanguage: 'en-GB',
  },
  'virtualcongress.easd.org': {
    instance: 'easd',
    defaultEvent: 'easd2023',
    defaultLanguage: 'en-GB',
  },
  'live.ersnet.org': {
    instance: 'ers',
    defaultEvent: 'ers2023',
    defaultLanguage: 'en-GB',
  },
  'eadv-virtual-lms.m-anage.com': {
    instance: 'eadv',
    defaultEvent: 'eadvcongress2022',
    defaultLanguage: 'en-GB',
  },
}

const headerConfig = {
  VMXHome: 'HomePage',
  VMXProgram: 'ProgramPage',
  VMXLive: 'LivePage',
  VMXShowreel: 'ProductsPage',
  IndustryLanding: 'IndustryLandingPage',
  VMXSymposia: 'SymposiaPage',
  VMXMeetings: 'MeetingHubPage',
  VMXPoster: 'PosterPage',
  VMXNews: 'NewsPage',
  VMXDonation: 'CharityPage',
}

const pageNames = {
  HomePage: 'Home',
  ProgramPage: 'Programme',
  ProgramLandingPage: 'Progamme Landing',
  LivePage: 'Live',
  ProductsPage: 'Products',
  IndustryLanding: 'Exhibition',
  IndustryLandingPage: 'Exhibition',
  IndustryLandingPageProfile: 'Profile',
  ListPage: 'Exhibition List',
  SymposiaPage: 'Satellites',
  MeetingHubPage: 'Meeting Hub',
  PosterPage: 'Posters',
  PosterLandingPage: 'Poster Landing',
  NewsPage: 'News',
  HelpDesk: 'Helpdesk',
  NoaccessPage: 'No Access',
  AuthPage: 'Auth',
  MyAreaPage: 'My Area',
  ContactPage: 'Contact',
  CharityPage: 'Charity',
  JobsPage: 'Jobs',
  AssemblyPage: 'General Assembly',
  LoginPage: 'Login',
  CreateAccountPage: 'Create Account',
  ConfirmationPage: 'Confirmation',
  PasswordRetrievalPage: 'Reset Password',
  ResetPasswordPage: 'Reset Password',
  UserFormPage: 'User',
  SurveyPage: 'Evaluation',
  PreceptorshipsPage: 'Preceptorships',
  PagFacultyDetailPage: 'Faculty Detail',
  PagPresentationDetailPage: 'Presentation Detail',
  PagSessionDetailPage: 'Session Detail',
  PagSearchPage: 'Search Overview',
  NotificationsPage: 'Notifications',
  MaintenancePage: 'Maintenance',
}

const authConfig = [
  'ProgramLandingPage',
  'LivePage',
  'ProductsPage',
  'IndustryLanding',
  'IndustryLandingPage',
  'IndustryLandingPageProfile',
  'ListPage',
  'SymposiaPage',
  'MeetingHubPage',
  'PosterLandingPage',
  'NewsPage',
  'HelpDesk',
  'MyAreaPage',
  'CharityPage',
  'JobsPage',
  'UserFormPage',
  'SurveyPage',
  'VenuePage',
  'PreceptorshipsPage',
  'PagSearchPage',
  'PosterPage',
  'IndustrySymposiaPage',
  'FacultySearchPage',
  'AbstractSearchPage',
  'PagSessionDetailPage',
  'PagPresentationDetailPage',
  'PagFacultyDetailPage',
  'ContentPage',
  'RegistrationPage',
  'ProfilePage',
  'PagPersonalProgrammePage',
]

const moreSpaceConfig = []

const languageIconMap = {
  'en-GB': '/assets/icons/flags/gb.png',
  'de-DE': '/assets/icons/flags/de.png',
  'fr-FR': '/assets/icons/flags/fr.png',
  'it-IT': '/assets/icons/flags/it.png',
  'en-US': '/assets/icons/flags/us.png',
  'es-ES': '/assets/icons/flags/es.png',
  'zh-CN': '/assets/icons/flags/cn.png',
  'tr-TR': '/assets/icons/flags/tr.png',
}

const myAreaModuleNames = {
  documents: {
    title: 'VMX.my_area_box_documents_title',
    buttonText: 'VMX.my_area_box_documents_button',
    description: 'VMX.my_area_box_documents_description',
  },
  submission: {
    title: 'VMX.my_area_box_submission_title',
    buttonText: 'VMX.my_area_box_submission_button',
    description: 'VMX.my_area_box_submission_description',
  },
  evaluation: {
    title: 'VMX.my_area_box_evaluation_title',
    buttonText: 'VMX.my_area_box_evaluation_button',
    description: 'VMX.my_area_box_evaluation_description',
  },
  program: {
    title: 'VMX.my_area_box_program_title',
    buttonText: 'VMX.my_area_box_program_button',
    description: 'VMX.my_area_box_program_description',
  },
  helpdesk: {
    title: 'VMX.my_area_box_helpdesk_title',
    buttonText: 'VMX.my_area_box_helpdesk_button',
    description: 'VMX.my_area_box_helpdesk_description',
  },
  profile: {
    title: 'VMX.my_area_box_profile_title',
    buttonText: 'VMX.my_area_box_profile_button',
    description: 'VMX.my_area_box_profile_description',
  },
  groups: {
    title: 'VMX.my_area_box_groups_title',
    buttonText: 'VMX.my_area_box_groups_button',
    description: 'VMX.my_area_box_groups_description',
  },
  inbox: {
    title: 'VMX.my_area_box_inbox_title',
    buttonText: 'VMX.my_area_box_inbox_button',
    description: 'VMX.my_area_box_inbox_description',
  },
  networking_settings: {
    title: 'VMX.my_area_box_networking_settings_title',
    buttonText: 'VMX.my_area_box_networking_settings_button',
    description: 'VMX.my_area_box_networking_settings_description',
  },
}

export default {
  myAreaModuleNames,
  authConfig,
  getInstanceConfig(instanceKey) {
    if (Object.prototype.hasOwnProperty.call(manageInstances, instanceKey)) {
      return manageInstances[instanceKey]
    }
    return null
  },
  getheaderConfig(moduleKey) {
    if (Object.prototype.hasOwnProperty.call(headerConfig, moduleKey)) {
      return headerConfig[moduleKey]
    }
    return null
  },
  getPageName(moduleKey) {
    if (Object.prototype.hasOwnProperty.call(pageNames, moduleKey)) {
      return pageNames[moduleKey]
    }
    return null
  },
  getDomainConfig(domain) {
    if (Object.prototype.hasOwnProperty.call(domainConfig, domain)) {
      return domainConfig[domain]
    }
    return null
  },
  getDomainConfigWithEvent(event) {
    const domain = Object.keys(domainConfig).filter(item => domainConfig[item].defaultEvent === event)
    if (domain.length > 0) {
      return domain[0]
    }
    return null
  },
  getAuthConfig(page) {
    return authConfig.includes(page)
  },
  getMoreSpaceConfig(page, pageArray) {
    if (Array.isArray(pageArray)) {
      const mergedArray = [...new Set([...pageArray])]
      return mergedArray.includes(page)
    }
    return moreSpaceConfig.includes(page)
  },
  getLanguageIcon(moduleKey) {
    if (Object.prototype.hasOwnProperty.call(languageIconMap, moduleKey)) {
      return languageIconMap[moduleKey]
    }
    return null
  },
}
