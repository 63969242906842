<template>
  <div v-if="inVMXCheck && inAppCheck && componentIsActive">
    <div
      class="programme-days-box main"
      v-if="days"
    >
      <h2
        class="programme-days-box__title"
        v-if="title"
      >
        {{ title }}
      </h2>
      <div :class="classes">
        <div
          class="programme-days-box__day"
          :class="`programDaysBox__day${key}`"
          v-for="(day, key) in days"
          :key="key"
        >
          <link-box
            :title="formatDayTitle(day.date, dayFormat)"
            :description="formatDescription(day)"
            :link="programLink(day)"
            :icon="icon"
            :background-color="backgroundColor"
            :tracking-content="trackingContent(day)"
            :text-color="textColor"
            component-name='ProgramDaysBox'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkBox from 'm-anage.com.vmx-components/src/LinkBox/index.vue'
import dateMixins from '~/mixins/dateMixins'
import componentIsActiveMixins from '~/mixins/componentIsActiveMixins'
import { mapGetters } from 'vuex'

export default {
  name: 'ProgramDaysBox',
  components: { LinkBox },
  mixins: [
    dateMixins,
    componentIsActiveMixins.componentProps,
    componentIsActiveMixins.method,
  ],
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'chevron-right',
    },
    dayFormat: {
      type: String,
      default: 'dddd, DD MMMM YYYY', // Wednesday, 3 June 2020
    },
    backgroundColor: {
      type: String,
      default: '#00000029',
    },
    textColor: {
      type: String,
      default: 'var(--primary)',
    },
    maxItemsPerLine: {
      type: Number,
      default: 5,
    },
    datesToDisplay: {
      type: Array,
      default: null,
    },
    viewType: {
      type: String,
      default: '',
    },
    viewSize: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: '',
    },
    displayPosterDays: {
      type: Boolean,
      default: false,
    },
    displayAllSessions: {
      type: Boolean,
      default: true,
    },
    displaySchedulerSessions: {
      type: Boolean,
      default: false,
    },
    onlyInApp: {
      type: Boolean,
      default: false,
    },
    onlyInVMX: {
      type: Boolean,
      default: false,
    },
    inApp: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      isAppContainer: 'instancesState/getIsAppContainer',
    }),
    classes() {
      const classes = ['programme-days-box__days-grid']
      classes.push(
        `programme-days-box__days-grid--max-items-per-line-${this.maxItemsPerLine}`,
      )
      return classes
    },
    days() {
      return this.$store.getters['program/getProgramDays'](this.datesToDisplay)
    },
    inAppCheck() {
      if (this.isAppContainer) {
        return this.inApp
      }
      return !this.onlyInApp
    },
    inVMXCheck() {
      if (this.isAppContainer && this.onlyInVMX) {
        return false
      }
      return true
    },
  },
  created() {
    this.$store.dispatch('program/fetchConfig')
  },
  methods: {
    trackingContent(day) {
      const trackingContent = {
        isActive: true,
        Action: 'ProgramDaysBox',
        EntityId: null,
        ActionContext: this.formatDayTitle(day.date, this.dayFormat),
      }
      return trackingContent
    },
    formatDescription(day) {
      if (this.displayPosterDays) {
        return `${day.PosterSessionsCount - day.PosterSessionsHideOnSchedulerCount} ${this.$te('VMX.program_days_box-poster') ? this.$t('VMX.program_days_box-poster') : 'Posters'}`
      }

      if (this.displaySchedulerSessions) {
        return `${day.sessionsCount - day.SessionsHideOnSchedulerCount - day.PosterSessionsCount} ${this.$te('VMX.program_days_box-session') ? this.$t('VMX.program_days_box-session') : 'Sessions'}`
      }
      if (this.displayAllSessions) return `${day.sessionsCount} ${this.$te('VMX.program_days_box-session') ? this.$t('VMX.program_days_box-session') : 'Sessions'}`

      return `${day.sessionsCount - day.PosterSessionsCount} ${this.$te('VMX.program_days_box-session') ? this.$t('VMX.program_days_box-session') : 'Sessions'}`
    },

    programLink(day) {
      return {
        name: 'PagSearchPage',
        query: {
          day: day.date,
          viewType: this.viewType,
          viewSize: this.viewSize,
          view: this.view,
        },
      }
    },
  },
}
</script>

<style lang="scss">
.programme-days-box__title {
  font-size: 26px;
  font-weight: bold;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
}

.programme-days-box__days-grid {
  // margin-bottom: -#{$c-grid-gutter};
  // margin-right: -#{$c-grid-gutter};
  gap: var(--link-box-grid-gap);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-width: 100%;
}

.programme-days-box__day {
  // margin: 0 10px 10px 0;
  flex: 0 0 100%;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  .link-box {
    padding: 1rem;
  }
  .link-box-content {
    align-items: center;
    .link-box__text {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  .link-box__title,
  .link-box__description {
    margin: 0
  }
  .link-box__title > div {
    font-size: 1rem;
    font-weight: bold;
  }
  .link-box__icon {
    flex: unset;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    border: 2px solid var(--primary);
    border-radius: 50%;
    svg {
      font-size: 1.5rem;
    }
  }
}
.programme-days-box__day:hover {
  .link-box__icon {
    background: var(--primary);
    color: #fff;
  }
}

/* tablet */

@media (min-width: 768px) {
  .programme-days-box__days-grid--max-items-per-line-2 {
    .programme-days-box__day {
      flex: 0 0 calc(50% - calc(var(--link-box-grid-gap) * 0.5));
      width: calc(50% - calc(var(--link-box-grid-gap) * 0.5));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-3 {
    .programme-days-box__day {
      flex: 0 0 calc(33.33333% - calc(var(--link-box-grid-gap) * calc(2 / 3)));
      width: calc(33.33333% - calc(var(--link-box-grid-gap) * calc(2 / 3)));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-4 {
    .programme-days-box__day {
      flex: 0 0 calc(25% - calc(var(--link-box-grid-gap) * 0.75));
      width: calc(25% - calc(var(--link-box-grid-gap) * 0.75));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-5 {
    .programme-days-box__day {
      flex: 0 0 calc(20% - calc(var(--link-box-grid-gap) * 0.8));
      width: calc(20% - calc(var(--link-box-grid-gap) * 0.8));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-6 {
    .programme-days-box__day {
      flex: 0 0 calc(16.666% - calc(var(--link-box-grid-gap) * calc(5 / 6)));
      width: calc(16.666% - calc(var(--link-box-grid-gap) * calc(5 / 6)));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-7 {
    .programme-days-box__day {
      flex: 0 0 calc(14.285% - calc(var(--link-box-grid-gap) * calc(6 / 7)));
      width: calc(14.285% - calc(var(--link-box-grid-gap) * calc(6 / 7)));
    }
  }
}

/* Desktop */
@media (min-width: 992px) {
  .programme-days-box__days-grid--max-items-per-line-2 {
    .programme-days-box__day {
      flex: 0 0 calc(50% - calc(var(--link-box-grid-gap) * 0.5));
      width: calc(50% - calc(var(--link-box-grid-gap) * 0.5));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-3 {
    .programme-days-box__day {
      flex: 0 0 calc(33.33333% - calc(var(--link-box-grid-gap) * calc(2 / 3)));
      width: calc(33.33333% - calc(var(--link-box-grid-gap) * calc(2 / 3)));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-4 {
    .programme-days-box__day {
      flex: 0 0 calc(25% - calc(var(--link-box-grid-gap) * 0.75));
      width: calc(25% - calc(var(--link-box-grid-gap) * 0.75));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-5 {
    .programme-days-box__day {
      flex: 0 0 calc(20% - calc(var(--link-box-grid-gap) * 0.8));
      width: calc(20% - calc(var(--link-box-grid-gap) * 0.8));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-6 {
    .programme-days-box__day {
      flex: 0 0 calc(16.666% - calc(var(--link-box-grid-gap) * calc(5 / 6)));
      width: calc(16.666% - calc(var(--link-box-grid-gap) * calc(5 / 6)));
    }
  }

  .programme-days-box__days-grid--max-items-per-line-7 {
    .programme-days-box__day {
      flex: 0 0 calc(14.285% - calc(var(--link-box-grid-gap) * calc(6 / 7)));
      width: calc(14.285% - calc(var(--link-box-grid-gap) * calc(6 / 7)));
    }
  }
}
</style>
