import apiBase from './base'
import store from '~/store'

async function getPersonalProgram() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PagPersonalSchedule/?eventid=${eventId}`
  const response = await apiBase.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return response
}

async function getAuthors() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PAGAuthors?eventid=${eventId}`

  const response = await apiBase.get(url)
  return response.data
}

async function getRooms() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PAGRooms?eventid=${eventId}`

  const response = await apiBase.get(url)
  return response.data
}

async function getPresentations() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PAGPresentations?eventid=${eventId}`

  const response = await apiBase.get(url)
  return response.data
}

async function getAbstracts() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PAGAbstracts?eventid=${eventId}`

  const response = await apiBase.get(url)
  return response.data
}

async function getSessions() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PAGSessionsV2?eventid=${eventId}`

  const response = await apiBase.get(url)
  return response.data
}

function downloadOutlookSession(params) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/DownloadOutlookAppointmentSession/?id=${eventId}&pSessionId=${params}`
  window.open(url, '_blank')
}

function downloadOutlookPresentation(params) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/DownloadOutlookAppointmentPresentation/?id=${eventId}&pPresentationId=${params}`
  window.open(url, '_blank')
}

async function setSessionViewCount(params) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/SetSessionViewCount/?eventId=${eventId}&sessionid=${params}`

  const response = await apiBase.get(url)
  return response.data
}

async function setPresentationViewCount(params) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/SetPresentationViewCount/?eventId=${eventId}&presentationId=${params}`
  const response = await apiBase.get(url)
  return response.data
}

async function removeSessionFavorite(params) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PersonalProgram/?eventid=${eventId}&pSessionId=${params}`
  await apiBase.callDelete(url)
}

async function addSessionFavorite() {
  const eventId = store.getters['instancesState/getEventId']
  const data = store.getters['personalProgram/symposiaIdList']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PersonalProgram/?eventid=${eventId}`
  const response = await apiBase.post(url, data)
  return response.data
}

async function createSessionRating(pSessionId, pContent, pRatingValue) {
  const data = store.getters['personalProgram/symposiaIdList']
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/CreateSessionRating/?id=${eventId}&pSessionId=${pSessionId}&pContent=${pContent}&pRatingValue=${pRatingValue}`
  const response = await apiBase.post(url, data)
  return response.data
}

async function getSessionRating(pSessionId) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/GetSessionRating/?id=${eventId}&pSessionId=${pSessionId}`
  const response = await apiBase.get(url)
  return response.data
}

async function addPresentationFavourite() {
  const eventId = store.getters['instancesState/getEventId']
  const data = store.getters['personalProgram/presentationIdList']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PersonalProgramPresentation/?eventid=${eventId}`
  await apiBase.post(url, data)
}

async function removePresentationFavourite(id) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PersonalProgramPresentation/?eventid=${eventId}&pPresentationId=${id}`
  await apiBase.callDelete(url)
}

async function addSpeakerFavourite(type) {
  const eventId = store.getters['instancesState/getEventId']
  const data = store.getters['personalProgram/speakerIdList']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PersonalProgramUser/?eventid=${eventId}&pProgramType=${type}`
  await apiBase.post(url, data)
}

async function removeSpeakerFavourite(id, type) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PersonalProgramUser/?eventid=${eventId}&pProgramType=${type}&pUserId=${id}`
  await apiBase.callDelete(url)
}

async function getPagMetaData() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/PAGData/?eventid=${eventId}`
  const response = await apiBase.get(url)
  return response.data ? response.data : null
}

async function getPersonalProgrammePDF() {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/DownloadPersonalProgram/?eventid=${eventId}`
  const response = await apiBase.get(url, { responseType: 'arraybuffer' })
  return response.data ? response.data : null
}
export default {
  getPersonalProgram,
  addSessionFavorite,
  removeSessionFavorite,
  downloadOutlookSession,
  downloadOutlookPresentation,
  createSessionRating,
  getSessionRating,
  getSessions,
  getPresentations,
  getAuthors,
  getAbstracts,
  addPresentationFavourite,
  removePresentationFavourite,
  addSpeakerFavourite,
  removeSpeakerFavourite,
  setPresentationViewCount,
  setSessionViewCount,
  getRooms,
  getPagMetaData,
  getPersonalProgrammePDF
}
