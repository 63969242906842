const initialState = {
  instance: null,
  instanceUrlParam: null,
  event: null,
  eventId: null,
  companyId: null,
  language: null,
  apiBaseUrl: null,
  identityUrl: null,
  surveyApi: null,
  cssVars: {},
  moduleName: null,
  modelConfig: null,
  backLink: false,
  currentTab: null,
  industryContentAccess: null,
  hcp: null,
  _paq: null,
  headerModel: null,
  themeLoaded: false,
  preview: false,
  loungePreview: false,
  linkPage: null,
  filterBy: null,
  groupBy: null,
  sortBy: null,
  themesStyles: null,
  superUserToggle: false,
  superUser: false,
  boothGuru: false,
  history: [],
  historyBack: false,
  menuDisabled: false,
  companyLoungeRedirect: false,
  disableHeader: false,
  headerModelInitalized: false,
  token: null,
  isAppContainer: false,
  isTerminalContainer: false,
  swDownload: false,
  maintenance: false,
  mtvApiBaseUrl: null
}

const getters = {
  getLinkPage(state) {
    return state.linkPage
  },
  getInstance(state) {
    return state.instance
  },
  getInstanceUrlParam(state) {
    return state.instanceUrlParam
  },
  getEvent(state) {
    return state.event
  },
  getCompanyId(state) {
    return state.companyId
  },
  getEventId(state) {
    return state.eventId
  },
  getLanguage(state) {
    return state.language
  },
  getApiBaseUrl(state) {
    return state.apiBaseUrl
  },
  getIdentityUrl(state) {
    return state.identityUrl
  },
  getSurveyApi(state) {
    return state.surveyApi
  },
  getModelConfig(state) {
    return state.modelConfig
  },
  getCssVars(state) {
    return state.cssVars
  },
  getCssVarValue: (state) => (name) => {
    if (!Object.prototype.hasOwnProperty.call(state.cssVars, name)) {
      return ''
    }
    return state.cssVars[name]
  },
  getThemesStyles(state) {
    return state.themesStyles
  },
  getModuleName(state) {
    return state.moduleName
  },
  getBackLink(state) {
    return state.backLink
  },
  getCurrentTab(state) {
    return state.currentTab
  },
  getIndustryContentAccess(state) {
    return state.industryContentAccess
  },
  getHcpStatus(state) {
    return state.hcp
  },
  getToken(state) {
    return state.token
  },
  getPaq(state) {
    return state._paq
  },
  getIsAppContainer(state) {
    return state.isAppContainer
  },
  getIsTerminalContainer(state) {
    return state.isTerminalContainer
  },
  getHeaderModel(state) {
    return state.headerModel
  },
  getInitializedHead(state) {
    return state.headerModelInitalized
  },
  getThemeLoaded(state) {
    return state.themeLoaded
  },
  getPreview(state) {
    return state.preview
  },
  getInVMX(state) {
    return state.inVMX
  },
  getSWDownload(state) {
    return state.swDownload
  },
  getLoungePreview(state) {
    return state.loungePreview
  },
  getFilterBy(state) {
    return state.filterBy
  },
  getGroupBy(state) {
    return state.groupBy
  },
  getSortby(state) {
    return state.sortBy
  },
  getSuperUser(state) {
    return state.superUser
  },
  getSuperUserToggle(state) {
    return state.superUserToggle
  },
  getBoothGuru(state) {
    return state.boothGuru
  },
  getHistory(state) {
    return state.history
  },
  getHistoryBack(state) {
    return state.historyBack
  },
  getMenuDisabled(state) {
    return state.menuDisabled
  },
  getCompanyLoungeRedirectDisabled(state) {
    return state.companyLoungeRedirect
  },
  getDisableHeader(state) {
    return state.disableHeader
  },
  getMaintenance(state) {
    return state.maintenance
  },
  getMTVApiBaseUrl(state) {
    return state.mtvApiBaseUrl
  },
}

const actions = {
  removeHistory({ state }) {
    if (state.history.length > 1) {
      state.history.pop()
    }
  },
}

const mutations = {
  setLinkPage(state, key) {
    state.linkPage = key
  },
  setInstance(state, key) {
    state.instance = key
  },
  setInstanceUrlParam(state, value) {
    state.instanceUrlParam = value
  },
  setEvent(state, key) {
    state.event = key
  },
  setCompanyId(state, key) {
    state.companyId = key
  },
  setEventId(state, val) {
    state.eventId = val
  },
  setMTVApiBaseUrl(state, url) {
    state.mtvApiBaseUrl = url
  },
  setLanguage(state, key) {
    document.documentElement.setAttribute('lang', key.substring(0,2) || 'en')
    state.language = key
  },
  setApiBaseUrl(state, url) {
    state.apiBaseUrl = url
  },
  setIdentityUrl(state, url) {
    state.identityUrl = url
  },
  setSurveyApi(state, url) {
    state.surveyApi = url
  },
  setModelConfig(state, key) {
    state.modelConfig = key
  },
  setCssVars(state, vars) {
    state.cssVars = vars
  },
  setModuleName(state, key) {
    state.moduleName = key
  },
  setBackLink(state, key) {
    state.backLink = key
  },
  setSWDownload(state, key) {
    state.swDownload = key
  },
  setCurrentTab(state, key) {
    state.currentTab = key
  },
  setIndustryContentAccess(state, key) {
    state.industryContentAccess = key
  },
  setInitializedHead(state, key) {
    state.headerModelInitalized = key
  },
  setHcpStatus(state, key) {
    state.hcp = key
  },
  setPaq(state, paq) {
    state._paq = paq
  },
  setHeaderModel(state, data) {
    state.headerModel = data
  },
  setThemeLoaded(state, loaded) {
    state.themeLoaded = loaded
  },
  setPreview(state, boolean) {
    state.preview = boolean
  },
  setLoungePreview(state, boolean) {
    state.loungePreview = boolean
  },
  setFilterBy(state, data) {
    state.filterBy = data
  },
  setGroupBy(state, data) {
    state.groupBy = data
  },
  setSortBy(state, data) {
    state.sortBy = data
  },
  setThemesStyles(state, data) {
    state.themesStyles = data
  },
  setSuperUser(state, boolean) {
    state.superUser = boolean
  },
  setToken(state, data) {
    state.token = data
  },
  setSuperUserToggle(state, boolean) {
    state.superUserToggle = boolean
  },
  setBoothGuru(state, boolean) {
    state.boothGuru = boolean
  },
  setHistory(state, history) {
    state.history.push(history)
  },
  setHistoryBack(state, boolean) {
    state.historyBack = boolean
  },
  setMenuDisabled(state, boolean) {
    state.menuDisabled = boolean
  },
  setInVMX(state, data) {
    state.inVMX = data
  },
  setCompanyLoungeRedirectDisabled(state, boolean) {
    state.companyLoungeRedirect = boolean
  },
  setDisableHeader(state, boolean) {
    state.disableHeader = boolean
  },
  setIsAppContainer(state, boolean) {
    state.isAppContainer = boolean
  },
  setIsTerminalContainer(state, boolean) {
    state.isTerminalContainer = boolean
  },
  setMaintenance(state, data) {
    state.maintenance = data
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
