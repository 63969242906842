import { mapGetters } from 'vuex'

const headerMixins = {
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
    }),
    headerConfig() {
      let config = null
      const defaultConfig = {
        menu: {
          myCongress: true,
          myCongressItems: [
            {
              name: 'My Programme',
              display: {
                industry: true,
                scientific: true,
              },
              url: {
                name: 'MyAreaPage',
                params: {
                  module: 'program',
                },
              },
            },
            {
              name: 'Certificates',
              display: {
                industry: true,
                scientific: true,
              },
              url: {
                name: 'MyAreaPage',
                params: {
                  module: 'evaluation',
                },
              },
            },
          ],
          menuItems: [
            {
              name: 'Home',
              url: 'HomePage',
              display: {
                industry: true,
                scientific: true,
              },
            },
            {
              name: 'Live Stream',
              url: 'LivePage',
              display: {
                industry: true,
                scientific: true,
              },
            },
            {
              name: 'Schedule',
              url: 'PagSearchPage',
              display: {
                industry: true,
                scientific: true,
              },
            },
            {
              name: 'Programme Search',
              url: 'PagSearchPage',
              icon: 'fa-calendar',
              display: {
                industry: true,
                scientific: true,
              },
            },
            {
              name: 'Industry',
              url: 'IndustryLandingPage',
              icon: 'fa-industry',
              display: {
                industry: true,
                scientific: false,
              },
            },
            {
              name: 'Satellite Symposia',
              url: 'SymposiaPage',
              display: {
                industry: true,
                scientific: false,
              },
            },
            {
              name: 'Product Hub',
              display: {
                industry: true,
                scientific: false,
              },
              subMenu: [
                {
                  name: 'Products',
                  url: 'ProductsPage',
                  display: {
                    industry: true,
                    scientific: true,
                  },
                },
              ],
            },
            {
              name: 'Meeting Hub',
              display: {
                industry: true,
                scientific: true,
              },
              subMenu: [
                {
                  name: 'Meeting',
                  url: 'MeetingHubPage',
                  display: {
                    industry: true,
                    scientific: true,
                  },
                },
              ],
            },
          ],
          bannerMenuItems: [],
        },
        banners: {
          main: {
            image: '',
            url: 'https://website.m-anage.com',
            alt: 'Event main logo',
            target: '_blank',
          },
          second: {
            image: '',
            alt: 'sponsor logo',
            url: 'MyAreaPage',
          },
        },
        background: '#fff',
        vmContainer: true,
      }
      if (this.configForKey('app_config').config) [config] = this.configForKey('app_config').config
      return config && config.defaults.header && config.defaults.header.menu.menuItems ? config.defaults.header : defaultConfig
    },
    isApp() {
      return localStorage.getItem('isApp')
    },
    breakpoint() {
      if (this.headerConfig.breakpoint && this.isApp) {
        return 2800
      }
      if (this.headerConfig.breakpoint && !this.isApp) {
        return this.headerConfig.breakpoint
      }
      return 1024
    },
    backLinkInNavigation() {
      if (this.headerConfig.backLinkInNavigation) {
        return this.headerConfig.backLinkInNavigation
      }
      return false
    },
    mobileContainerWidth() {
      if (this.headerConfig.mobileContainerWidth) {
        return this.headerConfig.mobileContainerWidth
      }
      return 95
    },
    mobileContainerMaxWidth() {
      if (this.headerConfig.mobileContainerMaxWidth) {
        return this.headerConfig.mobileContainerMaxWidth
      }
      return null
    },
    mobileContainerPadding() {
      if (this.headerConfig.mobileContainerPadding) {
        return this.headerConfig.mobileContainerPadding
      }
      return '0 .2rem'
    },
    tileNavigation() {
      if (this.headerConfig.tileNavigation) {
        return this.headerConfig.tileNavigation
      }
      return false
    },
    fullWidthBanner() {
      if (this.headerConfig.fullWidthBanner) {
        return this.headerConfig.fullWidthBanner
      }
      return false
    },
    fullWidthBannerLogo() {
      if (this.headerConfig.fullWidthBannerLogo) {
        return this.headerConfig.fullWidthBannerLogo
      }
      return null
    },
    fullWidthBannerBackground() {
      if (this.headerConfig.fullWidthBannerBackground) {
        return this.headerConfig.fullWidthBannerBackground
      }
      return false
    },
    fullWidthBannerBackgroundColor() {
      if (this.headerConfig.fullWidthBannerBackgroundColor) {
        return this.headerConfig.fullWidthBannerBackgroundColor
      }
      return '#fafafa'
    },
    fullWidthBannerBackgroundUrl() {
      if (this.headerConfig.fullWidthBannerBackgroundUrl) {
        return this.headerConfig.fullWidthBannerBackgroundUrl
      }
      return null
    },
    mainLogo() {
      if (this.headerConfig.banners.main.image) {
        return this.headerConfig.banners.main.image
      }
      return null
    },
    logoInMobileNav() {
      if ('logoInMobileNav' in this.headerConfig) {
        return this.headerConfig.logoInMobileNav
      }
      return false
    },
  },
}

export default headerMixins
