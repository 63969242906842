<template>
  <div
    class="new-footer__icons"
    v-if="socialMedia"
  >
    <font-awesome-icon
      v-for="(sm, idx) in socialMedia"
      :key="idx"
      :icon="icon(sm.icon)"
      @click="openLinkVMX(sm.name, sm.url, sm.target)"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import trackingApi from '~/api/tracking'
import appContainerMixins from 'm-anage.com.vmx-components/src/mixins/appContainerMixins'
export default {
  props: {
    socialMedia: {
      type: Array,
      required: true,
    },
  },
    computed: {
    ...mapGetters({
      isAppContainer: 'instancesState/getIsAppContainer',
    }),
  },
  mixins: [appContainerMixins],
  methods: {
    icon(icon) {
      // eslint-disable-next-line prefer-const
      if (icon) {
        let [first, ...rest] = icon.split('-')
        rest = rest.join('-')
        if (first && rest) {
          return [first, rest]
        }
      }
      return ['fa', 'question']
    },
    openLinkVMX(name, url, target = '_blank') {
      if (this.isAppContainer ) {
        this.openLink(url)
      } else {       
        window.open(url, target)
      }
      trackingApi.trackCustomAction(
        'vmx-footer-social-media',
        name,
        url,
      )
    },
  },
}
</script>
<style lang="scss">
.new-footer__icons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.new-footer__icons > svg {
  font-size:1.5rem;
  cursor: pointer;
}

</style>
