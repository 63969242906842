import apiBase from './base'
import store from '../store'

const industrySet = [
  'IndustryLandingPage',
  'IndustryLandingPageAskAQuestion',
  'general',
  'VMX',
  'VMXContentBox',
  'AppTranslations',
  'user_control',
  'myprofile',
  'modules',
  'VMXEventDashboard'
]

const userSet = [
  'IndustryLandingPage',
  'general',
  'VMX',
  'user_control',
  'myprofile',
  'AppTranslations',
]

const baseSet = [
  'IndustryLandingPage',
  'general',
  'VMX',
  'VMXContentBox',
  'AppTranslations',
]

const identitySet = [
  'VMX',
  'general',
  'ManageIdentity.Views.Account.Login',
  'ManageIdentity.Views.Account.ResetPassword',
  'ManageIdentity.Views.Account.ManageForgotPassword',
  'ManageIdentity.Views.Account.ForgotPasswordConfirmation',
  'ManageIdentity.Views.Account.Terms',
  'ManageIdentity.Views.Account.EmailNotConfirmed',
  'ManageIdentity.Views.Account.EmailNotConfirmedSend',
  'ManageIdentity.Views.Account.EmailConfirmed',
  'ManageIdentity.Views.Account.ConfirmationSend',
  'ManageIdentity.Views.Account.Lockout',
  'ManageIdentity.Views.Account.Register',
  'ManageIdentity.SharedResources',
  'ManageIdentity.Controllers.AccountController',
]

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getResourcesForSetName(event, locale, setName) {
  // eslint-disable-next-line max-len
  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/GetResources?eventshort=${event}&setname=${setName}&language=${locale}`

  if (useApiMockData) {
    url = `/api-mock/resources/${setName}.json`
  }
  const response = await apiBase.get(url)
  const responseTexts = response.data
  const texts = {}
  responseTexts.forEach((text) => {
    const key = `${setName}.${text.resourcename}`
    texts[key] = text.resourcevalue
  })
  return texts
}

async function getTexts(locale, resourceSet) {
  const event = store.getters['instancesState/getEvent']
  const getResourcesCalls = []

  if (resourceSet === 'identitySet') {
    identitySet.forEach((setName) => getResourcesCalls.push(getResourcesForSetName(event, locale, setName)))
  } else if (resourceSet === 'userSet') {
    userSet.forEach((setName) => getResourcesCalls.push(getResourcesForSetName(event, locale, setName)))
  } else if (resourceSet === 'baseSet') {
    baseSet.forEach((setName) => getResourcesCalls.push(getResourcesForSetName(event, locale, setName)))
  } else {
    industrySet.forEach((setName) => getResourcesCalls.push(getResourcesForSetName(event, locale, setName)))
  }
  return Promise.all(getResourcesCalls).then((results) => Object.assign({}, ...results))
}

export default {
  getTexts,

}
