import userApi from '../../api/user'

const user = {
  namespaced: true,
  state: {
    user: null,
    fetchUserPromise: null,
    userRequest: null,
    userFormFilled: null,
    userFormPromise: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setFetchPromise(state, payload) {
      state.fetchUserPromise = payload
    },
    setUserRequest(state, payload) {
      state.userRequest = payload
    },
    setUserFormFilled(state, payload) {
      state.userFormFilled = payload
    },
    setUserFormPromise(state, payload) {
      state.userFormPromise = payload
    },
    resetState(state) {
      state.user = null
      state.fetchUserPromise = null
      state.userRequest = null
      state.userFormFilled = null
      state.userFormPromise = null
    },
  },
  actions: {
    async loadUser({ commit, state }, payload) {
      const params = { ...payload }
      if (!params?.loadUserNew) {
        if (state.user !== null) {
          return state.user
        }
        if (state.fetchUserPromise) {
          return state.fetchUserPromise
        }
      }
      const fetchUserPromise = userApi.getManageUser().then((u) => {
        commit('setUser', u)
      })
      commit('setFetchPromise', fetchUserPromise)
      return fetchUserPromise
    },

    async userFormComplete({ commit, state }, payload) {
      const params = { ...payload }
      if (!params?.loadUserCompleteNew) {
        if (state.userFormFilled !== null) {
          return state.userFormFilled
        }
        if (state.userFormPromise) {
          return state.userFormPromise
        }
      }
      const fetchUserPromise = userApi.getUserFormComplete().then((u) => {
        commit('setUserFormFilled', u)
      })
      commit('setUserFormPromise', fetchUserPromise)
      return fetchUserPromise
    },
    async saveUserProfile({ commit }, payload) {
      const UserModel = { ...payload }
      const fetchPromise = await userApi.saveUser(UserModel)

      commit('setUserRequest', fetchPromise)
      return fetchPromise
    },
    reset({ commit }) {
      commit('resetState')
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    userId(state) {
      return state.user?.UserId
    },

    userFormFilled(state) {
      return state.userFormFilled
    },
  },
}

export default user
