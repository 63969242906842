import store from '~/store'

export default async function ({ next, to }) {
    const config = store.getters['config/configForKey']('app_config')
    const serviceWorkerConfig = config?.configV2?.defaultV2?.serviceWorker || null
    const appContainer = store.getters['instancesState/getIsAppContainer']
    const expirationDate = localStorage.getItem('cachedDate')
    const today = new Date().setDate(new Date().getUTCDate())

    if((!serviceWorkerConfig || !serviceWorkerConfig?.activateServiceWorker) && !appContainer) {
        console.log('clearcache called')
        window.clearcache()
    }

    if(serviceWorkerConfig?.activateServiceWorker && navigator.onLine && expirationDate) {
        if(today >= parseInt(expirationDate)) {
            console.log('clearcache called')
            window.clearcache()
            return
        }
    }

    next()
}
