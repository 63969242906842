<template>
  <div
    v-if="checkText"
    :hidden="preview"
    class="vm-ticker-container"
  >
    <div class="ticker-wrap">
      <div class="ticker">
        <div
          class="ticker__item"
          v-for="text in texts"
          :key="text.index"
          v-html="text"
        >
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '~/config'
import instancesStateMixins from '~/mixins/instanceStateMixins'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import tickerTextApi from '../api/tickerText'

dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)

export default {
  data() {
    return {
      texts: [],
      disableTicker: false,
      feed: null,
    }
  },
  mixins: [instancesStateMixins],
  methods: {
    ...mapActions({
      loadHeaderModel: 'header/loadModel',
    }),
  },
  computed: {
    ...mapGetters({
      headerModel: 'header/model',
      event: 'instancesState/getEvent',
      language: 'instancesState/getLanguage',
      config: 'config/configForKey',
    }),
    checkText() {
      return this.texts && this.texts.length > 0
    },
    // initialized() {
    //   return this.texts.length > 0;
    // },
    backendConfig() {
      return this.configForKey('app_config').config[0]
    },
  },
  async mounted() {
    await this.loadHeaderModel({
      event: this.event,
      language: this.language,
    })
    if (this.backendConfig.defaults?.rssTickerText) {
      window.loadRss(this.backendConfig.defaults.rssTickerText)
      setTimeout(() => {
        this.feed = JSON.parse(localStorage.getItem('rssfeed'))
        if (this.feed) {
          this.texts = this.feed.map((i) => `<a href="${i.link}" target="_blank">${i.title}</a>`)
        }
      }, 1000)
    } else if (this.backendConfig.defaults?.tickerText?.length) {
      const obj = this.backendConfig.defaults.tickerText.filter((t) => dayjs().isBetween(t.startTime, t.endTime))
      if (obj.length) {
        this.texts = obj[0].text
      }
    } else {
      this.texts = await tickerTextApi.getTickerText(
        this.headerModel.EventId,
        this.language,
      )
    }
  },
  watch: {
    $route() {
      this.disableTicker = config.getMoreSpaceConfig(this.$router.currentRoute.name)
    },
  },
}
</script>
