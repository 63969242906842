<template>
  <container>
    <side-nav-btn
      id="mobile-nav"
      class="vmx-header__bannerMenu-left--sideNav"
      :style="backlinkStyles"
      :breakpoint="breakpoint"
    >
    <history-back
      v-if="backLinkInNavigation && !currentPageIsHome"
      class="history-back-header"
    />
      <span @click="$emit('sideNavigationToggle')">
        <template v-if="headerMenu && headerMenu.menuIcon">
          <font-awesome-icon :icon="icon(headerMenu.menuIcon)" />
        </template>
        <template v-else>
          <font-awesome-icon :icon="['fa', 'bars']" />
        </template>
      </span>
      <span @click="$emit('sideNavigationToggle')">{{ $t("VMX.mobile_nav_open") }}</span>
    </side-nav-btn>
    <image-div-first
      class="vmx-header__bannerMenu-left--firstBanner" :breakpoint="breakpoint">
      <component
        :is="tag(banners.main)"
        v-bind="tagProps(banners.main)"
      >
        <template v-if="banners.main">
          <main-logo
            :src="banners.main.image"
            :alt="banners.main.alt"
            :style="banners.main.link ? 'cursor: pointer' : null"
          />
        </template>
      </component>
    </image-div-first>
    <image-div-second 
      class="vmx-header__bannerMenu-left--secondBanner" :breakpoint="breakpoint">
      <component
        :is="tag(banners.second)"
        v-bind="tagProps(banners.second)"
      >
        <template v-if="banners.second">
          <second-logo
            :src="banners.second.image"
            :alt="banners.second.alt"
            :style="banners.second.link ? 'cursor: pointer' : null"
          />
        </template>
      </component>
    </image-div-second>
  </container>
</template>

<script>
import styled from '@u-rogel/vue-emotion'
import headerMixins from '~/mixins/headerMixins'
import utils from '~/utilities/utils'
import backButtonMixins from '~/mixins/backButtonMixins'

const Container = styled.div`
  display: flex;
  padding: 16px 0;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`()

const ImageDiv = styled.div`
  height: 70px;
  > :first-child {
    width: 100%;
    height: 100%;
  }
`()

const ImageDivFirst = styled(ImageDiv)`
  @media (max-width: 768px) {
    width: 200px;
  }
  > :first-child {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
    }
  }
  :nth-child(2) {
    margin-left: auto;
    @media (min-width: ${({ breakpoint }) => breakpoint || 1024}px) {
      margin: 0 auto 0 0;
    }
  }
`({
  breakpoint: Number,
})

const ImageDivSecond = styled(ImageDiv)`
  :nth-child(2) {
    margin: 0 auto;
  }
  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    display: none;
  }
`({
  breakpoint: Number,
})

const MainLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`()

const SecondLogo = styled.img`
  width: 100%;
  max-height: 100%;
  object-fit: contain;
`()

const SideNavBtn = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    display: flex;
    align-items: center;
    max-width: 100px;
    > :nth-child(1) {
      margin-right: 5px;
    }
  }
  span {
    color: var(--header-menu-mobile, #333);
    font-weight: bold;
  }
`({
  breakpoint: Number,
})

export default {
  name: 'BannerLogos',
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  mixins: [
    headerMixins, backButtonMixins,
  ],
  components: {
    Container,
    ImageDivFirst,
    MainLogo,
    SecondLogo,
    SideNavBtn,
    ImageDivSecond,
  },
  props: {
    banners: {
      type: Object,
      default() {
        return {}
      },
    },
    headerMenu: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    backlinkStyles() {
      let backLinkStyle = null
      if (this.backLinkInNavigation && !this.currentPageIsHome && this.isMobile) {
        backLinkStyle = 'display: flex; gap: .25rem;'
      }
      return backLinkStyle
    },
    currentPageIsHome() {
      if (this.$route.name === 'HomePage') {
        return true
      }
      return false
    },
  },
  methods: {
    tag(obj) {
      if (!obj?.url) {
        return 'div'
      }
      if (!utils.isObject(obj.url) && utils.isUrl(obj.url)) {
        return 'a'
      }
      return 'router-link'
    },
    tagProps(obj) {
      if (!obj?.url) {
        return {}
      }
      const props = {}
      if (utils.isUrl(obj.url)) {
        props.href = obj.url
        props.target = obj.target ? obj.target : '_self'
      } else {
        let route = null
        if (utils.isObject(obj.url)) {
          route = utils.appendDefaultParamsToRoute(obj.url)
        } else {
          route = utils.getRouteWithDefaultParams(obj.url)
        }
        props.to = route
      }
      return props
    },
    icon(icon) {
      // eslint-disable-next-line prefer-const
      let [first, ...rest] = icon.split('-')
      rest = rest.join('-')
      if (first && rest) {
        return [first, rest]
      }
      return ['fa', 'question']
    },
  },
  mounted() {
    setTimeout(function(){ document.getElementById("mobile-nav").classList.add("show"); },500);
  },
}
</script>
<style lang="scss">
 @media (max-width: 1024px) {
    .dkk2022 .vmx-header__bannerMenu-left--sideNav{
    display:none !important;
  }
   .dkk2022 .vmx-header__bannerMenu-left--sideNav.show{
    display:flex !important;
  }
  }
 .history-back-header {
  margin: 0 !important;
  padding: 0;
 }
 .history-back-header a.back-link {
    margin: 0 !important;
    padding: 0 !important;
    color: var(--back-link) !important;
 }
 .history-back-header a.back-link svg {
    margin: 0 !important;
 }
 .history-back-header a.back-link span {
    display: none;
 }
 .vmx-header__bannerMenu-left--sideNav__flex {
  display: flex;
  flex-wrap: nowrap;
  gap: .25rem;
 }
</style>
