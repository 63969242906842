import { register } from 'register-service-worker'
import store from '~/store'
import VueCookies from 'vue-cookies'
import Vue from 'vue'

Vue.use(VueCookies)

export default async function ({ next, to }) {

    // get config first
    const config = store.getters['config/configForKey']('app_config')

    const serviceWorkerConfig = config?.configV2?.defaultV2?.serviceWorker || null

    //initialize service worker
    if(serviceWorkerConfig?.activateServiceWorker && navigator.onLine) {
        const apiBaseUrl = store.getters['instancesState/getApiBaseUrl']
        const event = store.getters['instancesState/getEvent']
        const language = store.getters['instancesState/getLanguage']
        const eventId = store.getters['instancesState/getEventId']

    
        const setNameToCache = [
             'VMXComponentVisibility',
             'PAG',
             'AppTranslations',
             'config_json',
             'VMXTheme',
             'PAGTheme',
             
        ]
        const apiPAGListToCache = [
             'api/Modules/PAGData/',
             'api/Modules/PAGRooms',
             'api/Modules/PAGAuthors',
             'api/Modules/PAGAbstracts',
             'api/Modules/PAGSessionsV3',
        ]
        // `${apiBaseUrl}/api/exhibition/CompanyAccounts/${eventId}/${language}`,

        
        const apiExhibitionsListToCache = [
             `${apiBaseUrl}/api/modules/IndustryLandingModel?shortname=${event}&language=${language}`,
             `${apiBaseUrl}/api/exhibition/CompanyAccounts/${eventId}/${language}?isPublic=false`,
             `${apiBaseUrl}/api/exhibition/CompanyAccountsV2?eventid=${eventId}&language=${language}`,
             `${apiBaseUrl}/api/exhibition/Symposia/${eventId}/${language}`,
        ]
    
    
        //need to get from configuration i guess
        const distributionsUrls = [
          'https://distribute.m-anage.com/from.storage?filecore=3V9IYgQ7r67lHrZiY1Ck5jmOlwwkUGpqM3UudCZy4Ho',
          'http://localhost:8080/us/sigmabc23/en-US/personalprogram'
        ]

        const configUrls = serviceWorkerConfig?.urlsToCache || []
    
        const createApiCachesList = () => {
            const tempResourcesList = setNameToCache.map((setName) => `${apiBaseUrl}/api/modules/GetResources?eventshort=${event}&setname=${setName}&language=${language}`)
            const tempPAGList = apiPAGListToCache.map((pagApi) => `${apiBaseUrl}/${pagApi}?eventid=${eventId}`)
            return tempResourcesList.concat(tempPAGList).concat(apiExhibitionsListToCache).concat(distributionsUrls).concat(configUrls)
        }
    
        function sendMessageToServiceWorker(msg) {
            navigator.serviceWorker?.ready
                ?.then((registration) => {
                    console.log('[APP SW] is ready');
                    // 2. Zugriff auf aktiven SW erhalten
                    if (registration.active) {
        
                        // 3. Nachricht senden
                        registration.active.postMessage({
                            msg: 'I AM APP SENDING A MESSAGE',
                            ...msg
                        });
                    } else {
                        // console.log("[APP SW] not active", registration)
                    }
                })
                .catch((err) => {
                    console.log('[APP SW] is not ready', err);
                });
        }
        
        function registerSW() {
          register(`${process.env.BASE_URL}manage-service-worker.js`, {
            ready() {
              console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
              )
           
            },
            registered() {
              console.log('Service worker has been registered.')
              console.log('Precaching needed data')
              if (localStorage.getItem('cachedDate') === null) {
                const expireInMinutes = serviceWorkerConfig?.expiryMinutes || 30
                const expirationDate = new Date().setDate(new Date().getUTCDate()) + expireInMinutes * 60000
                localStorage.setItem('cachedDate', expirationDate)
                console.log('Date added for cached refresh')
              }
             
            },
            cached() {
              console.log('Content has been cached for offline use.')
              store.commit('instancesState/setSWDownload', false)
               window.location.reload()
              // location.reload()
            },
            updatefound() {
              console.log('New content is downloading.')
              store.commit('instancesState/setSWDownload', true)
              localStorage.removeItem('cachedDate')
                    
            },
            updated() {
              console.log('New content is available; please refresh.')
              store.commit('instancesState/setSWDownload', false)
              //window.location.reload()


              //location.reload()
            },
            offline() {
              console.log('No internet connection found. App is running in offline mode.')
            },
            error(error) {
              console.error('Error during service worker registration:', error)
            }
          });
         
        }

      if ('serviceWorker' in navigator) {
        var refreshing;
        navigator.serviceWorker.addEventListener('controllerchange', (e) => {
          console.log('controllerchnage')
          if (refreshing) return;
          // window.location.reload()
          refreshing = true;
        });

        // window.addEventListener('load', async function() {
        //   const registration = await navigator.serviceWorker.register('/service-worker.js');
        //   if (registration.waiting && registration.active) {
        //     // The page has been loaded when there's already a waiting and active SW.
        //     // This would happen if skipWaiting() isn't being called, and there are
        //     // still old tabs open.
        //     console.log('Please close all tabs to get updates.');
        //   } else {
        //     // updatefound is also fired for the very first install. ¯\_(ツ)_/¯
        //     registration.addEventListener('updatefound', () => {
        //       registration.installing.addEventListener('statechange', () => {
        //         if (event.target.state === 'installed') {
        //           if (registration.active) {
        //             // If there's already an active SW, and skipWaiting() is not
        //             // called in the SW, then the user needs to close all their
        //             // tabs before they'll get updates.
        //             console.log('Please close all tabs to get updates.');
        //           } else {
        //             // Otherwise, this newly installed SW will soon become the
        //             // active SW. Rather than explicitly wait for that to happen,
        //             // just show the initial "content is cached" message.
        //             console.log('Content is cached for the first time!');
        //           }
        //         }
        //       });
        //     });
        //   }
        // });
        navigator.serviceWorker.addEventListener('message', (e) => {
          console.log(e.data.msg)
          if (e.data.msg == 'SW_ACTIVATED') {
             sendMessageToServiceWorker({ msg: 'APP_IMPORTLIST', payload: createApiCachesList() })
          }
          
           
        });
         navigator.serviceWorker.addEventListener('install', (e) => {
          console.log('installed')
           
         });
        //  navigator.storage.estimate() 
        // .then(function(estimate){ 
        //     console.log(`Using ${estimate.usage} out of ${estimate.quota} bytes.`); 
        // }); 
        //const isAppContainerX = !!document.getElementsByClassName("app_container").length
        if (localStorage.getItem('isApp') === 'true') {
           console.log('uuu')
          registerSW()
        } else {
          console.log('aaa')
         if ('serviceWorker' in navigator) {
            console.log('bbb')
         navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
          for (let registration of registrations) {
            console.log('1' + registration.active.scriptURL)
             console.log('2' + window.location.origin + '/manage-service-worker.js')
            if (registration.active.scriptURL == window.location.origin + '/manage-service-worker.js') {
               localStorage.removeItem('cachedDate')
                if ("caches" in window) {
                caches.keys().then(function (keyList) {
                  return Promise.all(
                    keyList.map(function (key) {
                      return caches.delete(key);
                    })
                  );
                });
              }
              registration.unregister();
              window.location.reload()
            }
            }
        });
}
          //window.location.reload()
        }
        
         
        } else {
            console.error('Service workers are not supported.');
        }

    }

    // else if(!serviceWorkerConfig && !serviceWorkerConfig?.activateServiceWorker && navigator.onLine) {
    //   console.log(navigator)
    //   console.log(self.serviceWorker)

    //   if ('serviceWorker' in navigator) {

    //   }
    // }

   
    // continue what ever happens
    next()
}
