import VueRouter from 'vue-router'
import Vue from 'vue'

import store from '~/store'
import ServiceWorker from '~/middleware/serviceWorker'
import MiddlewarePipeline from './middlewarePipeline'

import AuthMiddleware from '../middleware/auth'

import InstanceMiddleware from '../middleware/instance'
import LocaleMiddleware from '../middleware/locale'
import IndustryModelMiddleware from '../middleware/industryModel'
import ThemeMiddleware from '../middleware/theme'
import CustomProfileMiddleware from '../middleware/customProfile'
import ViewCountMiddleware from '../middleware/viewCount'
import TrackingMiddleware from '../middleware/tracking'
import VenuePageMiddleWare from '../middleware/venue'
import VenuePageRedirectMiddleWare from '../middleware/venueRedirect'
import HoldingMiddleware from '../middleware/holdingPage'
import AccessControlMiddleware from '../middleware/accessControl'
import ExternalAuthCodeMiddleware from '../middleware/externalAuthorizationCode'
import UserFormMiddleware from '../middleware/userForm'
import CompanyLoungeMiddleware from '../middleware/companyLounge'
import CompanyCheckMiddleware from '../middleware/companyCheck'
import NextPageMiddleware from '../middleware/nextPage'
import ClearServiceWorkerMiddleware from '../middleware/clearServiceWorkerCache'

import config from '../config'
import utils from '../utilities/utils'

const IndustryLandingPage = () => import('../pages/industryLandingPage/index.vue')
const ProgramLandingPage = () => import('../pages/programLanding/index.vue')
const HomePage = () => import('../pages/home/index.vue') // Master
const VenuePage = () => import('../pages/venue/index.vue')
const PlazaPage = () => import('../pages/plaza/index.vue')
const PreceptorshipsPage = () => import('../pages/preceptorships/index.vue')
const WorldVillage = () => import('../pages/customerPages/ERS/worldVillagePage/index.vue')
const MessagesPage = () => import('../pages/customerPages/ERS/messagesPage/index.vue')
const AwardWinnersPage = () => import('../pages/customerPages/ERS/awardWinnersPage/index.vue')
const BoothPage = () => import('../pages/booth/index.vue')
const AuthPage = () => import('../pages/auth/index.vue')
const LoginPage = () => import('../pages/login/index.vue')
const LogoutPage = () => import('../pages/logout/index.vue')
const CreateAccountPage = () => import('../pages/createAccount/index.vue')
const PasswordRetrievalPage = () => import('../pages/forgotPassword/index.vue')
const ConfirmationPage = () => import('../pages/confirmation/index.vue')
const ProductsPage = () => import('../pages/products/index.vue')
const SymposiaPage = () => import('../pages/symposia/index.vue')
const MeetingHubPage = () => import('../pages/meetingHub/index.vue')
const LivePage = () => import('../pages/live/index.vue')
const TvPage = () => import('../pages/tv/index.vue')
const DailyHighlightsPage = () => import('../pages/tv/pages/dailyHighlights.vue')
const SpeakerInterviewsPage = () => import('../pages/tv/pages/speakerInterviews.vue')
const WebinarPage = () => import('../pages/tv/pages/webinarPage.vue')
const EPosterPage = () => import('../pages/eposter/index.vue')
const PosterLandingPage = () => import('../pages/posterLanding/index.vue')
const NewsPage = () => import('../pages/news/index.vue')
const PresentationPlayer = () => '../pages/PresentationPlayer/index.vue'
const HelpdeskLandingPage = () => import('../pages/helpdesklanding/index.vue')
const NoaccessPage = () => import('../pages/noAccess/index.vue')
const MyAreaPage = () => import('~/pages/myArea/index.vue')
const SubmissionPage = () => import('~/pages/myArea/submissionIndex.vue')
const ContactPage = () => import('~/pages/contact/index.vue')
const Charity = () => import('~/pages/charity/index.vue')
const CharityPage = () => import('~/pages/charity/pages/root/index.vue')
const CharityProjectPage = () => import('~/pages/charity/pages/project/index.vue')
const HoldingPage = () => import('~/pages/holding/index.vue')
const JobsPage = () => import('~/pages/jobs/index.vue')
const UserFormPage = () => import('~/pages/userForm/index.vue')
const PageNotFound = () => import('../pages/PageNotFound.vue')
const ErrorPage = () => import('../pages/Error.vue')
const ResetPasswordPage = () => import('~/pages/resetPassword/index.vue')
const AssemblyPage = () => import('~/pages/assembly/index.vue')
const SurveyPage = () => import('~/pages/survey/index.vue')
const ContentPage = () => import('~/pages/content/index.vue')
const PagSearchPage = () => import('~/pages/pagPages/pages/programme/index.vue')
const RegistrationPage = () => import('~/pages/registration/index.vue')
const NotificationsPage = () => import('~/pages/notifications/index.vue')
const IndustryLandingPageNew = () => import('~/pages/industryLanding/index.vue') // rename after removing old industry landing
const ListPage = () => import('~/pages/list/index.vue')
const ProfilePage = () => import('~/pages/profile/index.vue')
const RegistrationLandingPage = () => import('~/pages/registrationLanding/index.vue')

Vue.use(VueRouter)

function commonMiddlewareList(middlewareList) {
  const cMiddlewareList = [
    NextPageMiddleware,
    InstanceMiddleware,
    ThemeMiddleware,
    HoldingMiddleware,
    AuthMiddleware,
    IndustryModelMiddleware,
    ExternalAuthCodeMiddleware,
    UserFormMiddleware,
    CompanyLoungeMiddleware,
  ]

  //Testing if this will affect the timing of trigger, making sure that the auth page won't interrupt the caching as the service workers got lots of page
  const serviceWorkerMiddlewareList = [
    ClearServiceWorkerMiddleware,
    ServiceWorker,
  ]
  return cMiddlewareList.concat(middlewareList).concat(serviceWorkerMiddlewareList)
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '',
      name: 'RedirectHome',
      meta: { middleware: [InstanceMiddleware] },
      redirect: () => {
        const domainConfig = config.getDomainConfig(utils.getDomain())
        if (domainConfig) {
          return {
            name: 'HomePage',
            params: {
              instance: domainConfig.instance,
              event: domainConfig.defaultEvent,
              language: domainConfig.defaultLanguage,
            },
          }
        }
        return {
          name: 'vmx-landing',
        }
      },
    },
    {
      path: '/venue/:instance/:event/:language',
      component: VenuePage,
      name: 'VenuePage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          VenuePageMiddleWare,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // '/IndustryLandingPage/:instance/:event/:language/
      // Industry Profile Page
      path:
        '/industrylandingpage/:instance/:event/:language/:page/:companyAccountId',
      component: IndustryLandingPage,
      name: 'IndustryLandingPageProfile',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          CustomProfileMiddleware,
          CompanyCheckMiddleware,
          ViewCountMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Industry Master Page
      path: '/industrylandingpage/:instance/:event/:language/:page?',
      component: IndustryLandingPage,
      name: 'IndustryLandingPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Products Page
      path: '/products/:instance/:event/:language',
      component: ProductsPage,
      name: 'ProductsPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // VMX Program Landing Page
      path: '/programlanding/:instance/:event/:language',
      component: ProgramLandingPage,
      name: 'ProgramLandingPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Jobs Page
      path: '/:instance/:event/:language/jobs',
      component: JobsPage,
      name: 'JobsPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Jobs Detail Page
      path: '/:instance/:event/:language/jobs/:jobsId',
      component: JobsPage,
      name: 'JobsPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // VMX Symposia Page
      path: '/symposia/:instance/:event/:language',
      component: SymposiaPage,
      name: 'SymposiaPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // VMX MeetinhHub Page
      path: '/meetinghub/:instance/:event/:language',
      component: MeetingHubPage,
      name: 'MeetingHubPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Home Page
      path: '/home/:instance/:event/:language',
      component: HomePage,
      name: 'HomePage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          VenuePageRedirectMiddleWare,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Holding Page
      path: '/holding/:instance/:event/:language',
      component: HoldingPage,
      name: 'HoldingPage',
      meta: {
        middleware: [InstanceMiddleware, ThemeMiddleware, LocaleMiddleware],
      },
    },
    {
      // Plaza Page
      path: '/plaza/:instance/:event/:language',
      component: PlazaPage,
      name: 'PlazaPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Notifications Page
      path: '/notifications/:instance/:event/:language',
      component: NotificationsPage,
      name: 'NotificationsPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Preceptorships Page
      path: '/preceptorships/:instance/:event/:language',
      component: PreceptorshipsPage,
      name: 'PreceptorshipsPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Tv Page
      path: '/tv/:instance/:event/:language',
      component: TvPage,
      name: 'TvPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Tv Page
      path: '/tv/:instance/:event/:language/dailyhighlights',
      component: DailyHighlightsPage,
      name: 'DailyHighlightsPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Tv Page
      path: '/tv/:instance/:event/:language/speakerinterviews',
      component: SpeakerInterviewsPage,
      name: 'SpeakerInterviewsPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Tv Page
      path: '/tv/:instance/:event/:language/podcast',
      component: WebinarPage,
      name: 'WebinarPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Plaza Page
      path: '/worldvillage/:instance/:event/:language',
      component: WorldVillage,
      name: 'WorldVillage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Plaza Page
      path: '/messages/:instance/:event/:language',
      component: MessagesPage,
      name: 'MessagesPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Plaza Page
      path: '/:instance/:event/:language/award-winners',
      component: AwardWinnersPage,
      name: 'AwardWinnersPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Plaza Page
      path: '/booth/:instance/:event/:language',
      component: BoothPage,
      name: 'BoothPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Live Page
      path: '/live/:instance/:event/:language',
      component: LivePage,
      name: 'LivePage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // EPoster Page
      path: '/eposter/:instance/:event/:language',
      component: EPosterPage,
      name: 'EPosterPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Poster Page
      path: '/:instance/:event/:language/poster',
      component: PagSearchPage,
      name: 'PosterPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Industry Symposia Search Page
      path: '/:instance/:event/:language/industrySymposia',
      component: PagSearchPage,
      name: 'IndustrySymposiaPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Industry Symposia Search Page
      path: '/:instance/:event/:language/abstractsolr',
      component: PagSearchPage,
      name: 'PagSearchPageSolr',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Presentation Search Page
      path: '/:instance/:event/:language/presentationSearch',
      component: PagSearchPage,
      name: 'PresentationSearchPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Faculty Search Page
      path: '/:instance/:event/:language/facultySearch',
      component: PagSearchPage,
      name: 'FacultySearchPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Abstract Search Page
      path: '/:instance/:event/:language/abstractSearch',
      component: PagSearchPage,
      name: 'AbstractSearchPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Poster Landing Page
      path: '/posterlanding/:instance/:event/:language',
      component: PosterLandingPage,
      name: 'PosterLandingPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // News Page
      path: '/news/:instance/:event/:language',
      component: NewsPage,
      name: 'NewsPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // PresentationPlayer
      path: '/presentationplayer/:instance/:event/:language/:presentationId',
      component: PresentationPlayer,
      name: 'PresentationPlayer',
      meta: {
        middleware: commonMiddlewareList([LocaleMiddleware, TrackingMiddleware]),
      },
    },
    {
      // CharityGroup
      path: '/:instance/:event/:language/charity',
      component: Charity,
      name: 'CharityPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
      children: [
        {
          // CharityPage
          path: '',
          component: CharityPage,
          name: 'CharityPage',
          meta: {
            middleware: commonMiddlewareList([
              LocaleMiddleware,
              AccessControlMiddleware,
              TrackingMiddleware,
            ]),
          },
        },
        {
          // CharityProjectPage
          path: 'project/:id',
          component: CharityProjectPage,
          name: 'CharityProjectPage',
          meta: {
            middleware: commonMiddlewareList([
              LocaleMiddleware,
              AccessControlMiddleware,
              TrackingMiddleware,
            ]),
          },
        },
      ],
    },
    {
      // Faculty Detail Page
      path: '/:instance/:event/:language/faculty/:userId',
      component: PagSearchPage,
      name: 'PagFacultyDetailPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Programme Search
      path: '/:instance/:event/:language/search',
      component: PagSearchPage,
      name: 'PagSearchPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Session Detail Page
      path: '/:instance/:event/:language/session/:sessionId',
      component: PagSearchPage,
      name: 'PagSessionDetailPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
    // Session Detail Page
      path: '/:instance/:event/:language/personalprogram',
      component: PagSearchPage,
      name: 'PagPersonalProgrammePage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    // {
    //   // Poster Detail Page
    //   path: '/:instance/:event/:language/poster/:posterId',
    //   component: PagSessionDetailPage,
    //   name: 'PagSessionDetailPage',
    //   meta: {
    //     middleware: commonMiddlewareList([
    //       AccessControlMiddleware,
    //       LocaleMiddleware,
    //       TrackingMiddleware,
    //     ]),
    //   },
    // },
    {
      // Presentation  Detail Page
      path: '/:instance/:event/:language/presentation/:presentationId',
      component: PagSearchPage,
      name: 'PagPresentationDetailPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    // {
    //   // Poster Presentation  Detail Page
    //   path: '/:instance/:event/:language/posterpresentation/:posterPresentationId',
    //   component: PagPresentationDetailPage,
    //   name: 'PagPresentationDetailPage',
    //   meta: {
    //     middleware: commonMiddlewareList([
    //       AccessControlMiddleware,
    //       LocaleMiddleware,
    //       TrackingMiddleware,
    //     ]),
    //   },
    // },
    {
      // Content Page
      path: '/:instance/:event/:language/page/:pageName',
      component: ContentPage,
      name: 'ContentPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // HelpdesklandingPage
      path: '/helpdesk/:instance/:event/:language',
      component: HelpdeskLandingPage,
      name: 'HelpDesk',
      meta: {
        middleware: commonMiddlewareList([LocaleMiddleware, TrackingMiddleware]),
      },
    },
    {
      // ContactPage
      path: '/contact/:instance/:event/:language',
      component: ContactPage,
      name: 'ContactPage',
      meta: {
        middleware: commonMiddlewareList([LocaleMiddleware, AccessControlMiddleware, TrackingMiddleware]),
      },
    },
    {
      path: '/:instance/:event/:language/submission',
      component: SubmissionPage,
      name: 'SubmissionPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      path: '/:instance/:event/:language/submission*',
      component: SubmissionPage,
      name: 'SubmissionPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      path: '/myarea/:instance/:event/:language/:module?/:id?',
      component: MyAreaPage,
      name: 'MyAreaPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Auth confirmation page
      path: '/auth/:instance/:event/:language',
      component: AuthPage,
      name: 'AuthPage',
      meta: {
        middleware: [InstanceMiddleware, ThemeMiddleware, LocaleMiddleware],
      },
    },
    {
      path: '/login/:instance/:event/:language',
      component: LoginPage,
      name: 'LoginPage',
      meta: {
        middleware: [InstanceMiddleware, ClearServiceWorkerMiddleware, ThemeMiddleware, LocaleMiddleware],
      },
    },
    {
      path: '/logout/:instance/:event/:language',
      component: LogoutPage,
      name: 'LogoutPage',
      meta: {
        middleware: [InstanceMiddleware, ClearServiceWorkerMiddleware, ThemeMiddleware, LocaleMiddleware],
      },
    },
    {
      path: '/createaccount/:instance/:event/:language',
      component: CreateAccountPage,
      name: 'CreateAccountPage',
      meta: {
        middleware: [InstanceMiddleware, ClearServiceWorkerMiddleware, ThemeMiddleware, LocaleMiddleware],
      },
    },
    {
      path: '/forgotpassword/:instance/:event/:language',
      component: PasswordRetrievalPage,
      name: 'PasswordRetrievalPage',
      meta: {
        middleware: [InstanceMiddleware, ClearServiceWorkerMiddleware, ThemeMiddleware, LocaleMiddleware],
      },
    },
    {
      path: '/confirmation/:instance/:event/:language',
      component: ConfirmationPage,
      name: 'ConfirmationPage',
      meta: {
        middleware: [InstanceMiddleware, ClearServiceWorkerMiddleware, ThemeMiddleware, LocaleMiddleware],
      },
    },
    {
      path: '/resetpassword/:instance/:event/:language',
      component: ResetPasswordPage,
      name: 'ResetPasswordPage',
      meta: {
        middleware: [InstanceMiddleware, ClearServiceWorkerMiddleware, ThemeMiddleware, LocaleMiddleware],
      },
    },
    {
      path: '/userform/:instance/:event/:language',
      component: UserFormPage,
      name: 'UserFormPage',
      meta: {
        middleware: [
          InstanceMiddleware,
          LocaleMiddleware,
          ThemeMiddleware,
          ClearServiceWorkerMiddleware,
          HoldingMiddleware,
          AuthMiddleware,
          TrackingMiddleware,
        ],
      },
    },
    {
      // Assembly Page
      path: '/assembly/:instance/:event/:language',
      component: AssemblyPage,
      name: 'AssemblyPage',
      meta: {
        middleware: [
          InstanceMiddleware,
          LocaleMiddleware,
          ThemeMiddleware,
          AuthMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ],
      },
    },
    {
      // Survey Page
      path: '/:instance/:event/:language/survey',
      component: SurveyPage,
      name: 'SurveyPage',
      meta: {
        middleware: [
          InstanceMiddleware,
          LocaleMiddleware,
          ThemeMiddleware,
          ClearServiceWorkerMiddleware,
          AuthMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ],
      },
    },
    {
      // Registration Page
      path: '/:instance/:event/:language/registration/:subApplicationRoute?',
      component: RegistrationPage,
      name: 'RegistrationPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          VenuePageRedirectMiddleWare,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Registration landing Page
      path: '/:instance/:event/:language/registrationLanding',
      component: RegistrationLandingPage,
      name: 'RegistrationLandingPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          VenuePageRedirectMiddleWare,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // industry landing
      path: '/:instance/:event/:language/industryLanding',
      component: IndustryLandingPageNew,
      name: 'IndustryLanding',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      //  list pages
      path: '/:instance/:event/:language/list/:page?',
      component: ListPage,
      name: 'ListPage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // Profile Pages
      path: '/:instance/:event/:language/profile/:companyAccountId/:page?',
      component: ProfilePage,
      name: 'ProfilePage',
      meta: {
        middleware: commonMiddlewareList([
          LocaleMiddleware,
          AccessControlMiddleware,
          CustomProfileMiddleware,
          CompanyCheckMiddleware,
          ViewCountMiddleware,
          TrackingMiddleware,
        ]),
      },
    },
    {
      // No Access
      path: '/noaccess/:instance/:event/:language',
      component: NoaccessPage,
      name: 'NoaccessPage',
      meta: {
        middleware: [
          InstanceMiddleware,
          LocaleMiddleware,
          ThemeMiddleware,
          TrackingMiddleware,
        ],
      },
    },
    {
      path: '/error',
      component: ErrorPage,
      name: 'error',
      meta: { analyticsIgnore: true },
    },
    {
      path: '/',
      name: 'vmx-landing',
      redirect: '/home/release/vmx/en-GB',
    },
    {
      path: '*', component: PageNotFound, meta: { analyticsIgnore: true },
    },
    {
      path: '/not-found', name: 'not-found', component: PageNotFound, meta: { analyticsIgnore: true },
    },
  ],
  scrollBehavior(to) {
    if ('parentIFrame' in window) {
      window.parentIFrame.scrollToOffset(0, 0)
    } else if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth',
          })
        }, 500)
      })
    } else {
      return { x: 0, y: 0 }
    }
    return null
  },
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const { middleware } = to.meta
  const context = {
    to,
    from,
    next,
    router,
  }
  return middleware[0]({
    ...context,
    next: MiddlewarePipeline(context, middleware, 1),
  })
})

router.afterEach((to) => {
  const headerModel = store.getters['header/model']
  const newPageName = utils.setPageTitle(to.name)
  // TODO: get event title + page title
  let eventTitle = 'VMX'
  let pageTitle = config.getPageName(to.name)
  if (headerModel && headerModel.EventName) {
    eventTitle = headerModel.EventName
  }
  if (newPageName) {
    pageTitle = newPageName
  }
  document.title = `${eventTitle} - ${pageTitle}`
})

export default router
